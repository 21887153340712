import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Orders.css";
import PendingOrders from "./CodOrderTabs/PendingOrders";
// import DeliveredOrders from "./OrderTabs/DeliveredOrders";
// import ReadyforShippedOrders from "./OrderTabs/ReadyforShippedOrders";
// import ShippedOrders from "./OrderTabs/ShippedOrders";
// import BankTransferOrders from "./OrderTabs/BankTransferOrders";

function CodOrders() {
  const [deliveredcount, setDeliveredcount] = useState(0);
  const [pendingcount, setPendingcount] = useState(0);
  const [readyForShippedcount, setReadyForShippedcount] = useState(0);
  const [shippedcount, setShippedcount] = useState(0);
  const [bankTransfercount, setBankTransfercount] = useState(0);

  return (
    <Tabs>
      <TabList>
        <Tab>
          Pending{" "}
          <span className="badge badge-danger badge-counter">{pendingcount}</span>
        </Tab>
      </TabList>

      <TabPanel>
        <PendingOrders setPendingcount={setPendingcount}></PendingOrders>
      </TabPanel>
    </Tabs>
  );
}

export default CodOrders;
