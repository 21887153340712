import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../../Utils/Axios";
import Slider from 'rsuite/Slider';
import { useAlert } from 'react-alert'


function OrderDetails(props) {
    const alert = useAlert();
    const [orderId, setOrderId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    const [order, setOrder] = useState({});
    const [percent, setPercent] = useState(40);
    const [customer, setCustomer] = useState({});
    const [orderItems, setOrderItems] = useState([]);
    const [paymentType, setPaymentType] = useState('CARD');
    const [paymentData, setPaymentData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getOrderDetail();
    }, [orderId]);


    const submitPayment = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("order_id", order.id);
        if (order.installment == 1) {
            formData.append("amount", (order.net * percent) / 100);
        } else {
            formData.append("amount", order.pending_amount);
        }

        formData.append("installment", order.installment);
        formData.append("payment_type", paymentType);

        axios(`Orders/submit_payment`, {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "x-api-key": JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((res) => {
                if (res.data.status === false) {
                    getOrderDetail();
                    setLoading(false);

                } else {
                    getOrderDetail();
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const getOrderDetail = () => {
        setLoading(true);
        axios(`Orders/get_cod_order_detail?order_id=${orderId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "x-api-key": JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((res) => {
                if (res.data.status === false) {
                } else {
                    setOrder(res.data.data.order);
                    setCustomer(res.data.data.customer);
                    setOrderItems(res.data.data.order_items)
                    setPaymentData(res.data.data.payment_data);
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const updatePaymentStatus = (id) => {
        setLoading(true);

        axios(`Orders/update_partial_payment_status?id=${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "x-api-key": JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((res) => {
                if (res.data.status === false) {
                    getOrderDetail();
                    setLoading(false);

                } else {
                    getOrderDetail();
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div class="container-fluid">
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner
                text="Loading ..."
            >
                <h1 class="h3 mb-2 text-gray-800">Order Details</h1>

                <div class="card shadow mb-4">
                    <div class="container">



                        <div class="property-new-container">

                            <div id="payments" class="payments hide">

                                <div class="bottom-segment">

                                    <div class="summary-body">
                                        <div class="detailed-summary">
                                            <div class="category">
                                                <div class="category-title">Order ID</div>
                                                <div class="category-amount">{order.order_no}</div>
                                            </div>
                                            <div class="category">
                                                <div class="category-title">Purchased On</div>
                                                <div class="category-amount">{order.created_at}</div>
                                            </div>

                                            <div class="category">
                                                <div class="category-title">Total Amount</div>
                                                <div class="category-amount">{order.net + " " + order.currency}</div>
                                            </div>
                                            <div class="category">
                                                <div class="category-title">Customer</div>
                                                <div class="category-amount">{customer.name + ',' + customer.address + ',' + customer.area + ',' + customer.email + ',' + customer.mobile_no}</div>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered  ">
                                                <thead>
                                                    <tr>

                                                        <th>Name</th>
                                                        <th>Qty</th>
                                                        <th>Price </th>
                                                        <th>Total </th>



                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {orderItems.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                {/* <td>{value.order_id}</td> */}
                                                                <td>
                                                                    {value.name}
                                                                </td>
                                                                <td>
                                                                    {value.qty}
                                                                </td>
                                                                <td>{value.special_price > 0 ? value.special_price : value.price}</td>
                                                                <td>{value.subtotal}</td>


                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>






                                {order.installment <= 2 ? (
                                    <div class="top-segment">

                                        <div class="payment-slider">
                                            <div class="down-payment">
                                                <div class="row align-items-center">
                                                    <div class="col-sm-2 payment-text">Down payment</div>
                                                    {order.installment == 1 ? (<> <div class="slider-wrapper col-sm-4">
                                                        <Slider progress
                                                            defaultValue={40}
                                                            onChange={value => {
                                                                setPercent(value)
                                                            }}></Slider>
                                                    </div>
                                                        <div class="col-sm-2 amount-paid">
                                                            {(percent * order.net) / 100}
                                                        </div> </>) : (<div class="col-sm-2 amount-paid">
                                                            {order.pending_amount}
                                                        </div>)}


                                                    <div class="col-sm-3">

                                                        <div class="category">
                                                            <div class="category-title">Payment type</div>
                                                            <div class="category-amount"><div class="form-group">

                                                                <select onChange={(e) => setPaymentType(e.target.value)} class="form-control  form-control-sm  ">
                                                                    <option value="CARD">CARD</option>
                                                                    <option value="BANK">Bank through</option>
                                                                    <option value="CASH" >Cash On delivery</option>

                                                                </select>
                                                            </div></div>
                                                        </div>
                                                    </div>




                                                    <div class="col-sm-1 amount-paid">
                                                        <button className="btn btn-sm btn-success" onClick={submitPayment}> Pay</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="remaining-amount">
                                            <div class="row row-compact">
                                                <div class="col-sm-3 payment-text">Remaining amount</div>
                                                <div class="slider-wrapper col-sm-7"><div class="date-slider remaining-amount-slider"><div id="remaining-payment-dot" class="dot due2" ></div></div></div>
                                                <div class="col-sm-2 due-date">AED 600</div>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>) : ''}

                                <div class="top-segment">
                                    <div class="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered  ">
                                                <thead>
                                                    <tr>

                                                        <th>Installment</th>
                                                        <th>Amount</th>
                                                        <th>Payment Method</th>
                                                        <th>Payment Status</th>
                                                        <th>Date</th>
                                                        <th>Action</th>
                                                        <th></th>


                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {paymentData.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                {/* <td>{value.order_id}</td> */}
                                                                <td>
                                                                    {value.installment}
                                                                </td>
                                                                <td>
                                                                    {value.amount}
                                                                </td>
                                                                <td>{value.payment_type}</td>
                                                                <td>{value.payment_status == 1 ? <span className=" btn-sm btn-success ">Paid</span> : <span className=" btn-sm btn-danger ">Pending</span>}</td>
                                                                <td>{value.created_at}</td>
                                                                <td>
                                                                    {value.payment_link && value.payment_status != 1 ? (<button title="Copy Payment Link" onClick={() => {
                                                                        navigator.clipboard.writeText(value.payment_link);
                                                                        alert.info("Payment Link Copied");
                                                                    }} className="btn btn-sm btn-info" ><i className="fa fa-sm fa-copy"></i></button>) : ''}
                                                                    {value.payment_status != 1 ? (<button className="btn btn-sm btn-success" onClick={() => updatePaymentStatus(value.id)} title="Change To Paid" ><i className="fa fa-sm fa-check"></i></button>) : ''}
                                                                    {value.payment_status == 1 ? "Paid On : " + value.paid_on : ''}
                                                                    
                                                                    
                                                                    </td>
                                                                    <td>{value.payment_status == 1 ? (<a href={"https://uae-en.shopkees.com/shpcpanel/Orders/print_partial_payment_receipt/"+value.id}  target="_blank" className="btn btn-sm btn-info"  title="Payment Receipt" ><i className="fa fa-sm fa-print"></i></a>) : ''}</td>

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </LoadingOverlay>
        </div>
    );
}

export default OrderDetails;
