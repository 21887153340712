import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import {Link} from "react-router-dom";
import $ from 'jquery';
const toggle = () =>{
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $('.sidebar .collapse').collapse('hide');
    };

  }
  
function Header() {
  const [user, setUser] = useState({});
  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = () => {
    axios(`Users/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setUser(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const logOut = () => {
    localStorage.removeItem("data");
  };
    return (
        
              <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top  toggled ">
                <button 
                   onClick={toggle}
                  id="sidebarToggleTop"
                  class="btn btn-link d-md-none rounded-circle mr-3"
                >
                  <i class="fa fa-bars"></i>
                </button>
        
                <ul class="navbar-nav ml-auto">
                  <div className="nav-item dropdown no-arrow mx-1"> 
                  <Link
                      class="nav-link dropdown-toggle"
                      id="userDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ><span class="mr-2 d-none d-lg-inline text-gray-600 small">
                       <h2 style={{textTransform: "capitalize" }}>{user.display_name}</h2> 
                      </span>
                      </Link></div>
                  {/* <li class="nav-item dropdown no-arrow mx-1">
                    <Link
                      class="nav-link dropdown-toggle"
                      id="alertsDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-bell fa-fw"></i>
        
                      <span class="badge badge-danger badge-counter">3+</span>
                    </Link>
        
                    <div
                      class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="alertsDropdown"
                    >
                      <h6 class="dropdown-header">Alerts Center</h6>
                      <Link class="dropdown-item d-flex align-items-center">
                        <div class="mr-3">
                          <div class="icon-circle bg-primary">
                            <i class="fas fa-file-alt text-white"></i>
                          </div>
                        </div>
                        <div>
                          <div class="small text-gray-500">December 12, 2019</div>
                          <span class="font-weight-bold">
                            A new monthly report is ready to download!
                          </span>
                        </div>
                      </Link>
                      <Link class="dropdown-item d-flex align-items-center">
                        <div class="mr-3">
                          <div class="icon-circle bg-success">
                            <i class="fas fa-donate text-white"></i>
                          </div>
                        </div>
                        <div>
                          <div class="small text-gray-500">December 7, 2019</div>
                          $290.29 has been deposited into your account!
                        </div>
                      </Link>
                      <Link class="dropdown-item d-flex align-items-center">
                        <div class="mr-3">
                          <div class="icon-circle bg-warning">
                            <i class="fas fa-exclamation-triangle text-white"></i>
                          </div>
                        </div>
                        <div>
                          <div class="small text-gray-500">December 2, 2019</div>
                          Spending Alert: We've noticed unusually high spending for your
                          account.
                        </div>
                      </Link>
                      <Link class="dropdown-item text-center small text-gray-500">
                        Show All Alerts
                      </Link>
                    </div>
                  </li> */}
        
                  <div class="topbar-divider d-none d-sm-block"></div>
        
                  <li class="nav-item dropdown no-arrow">
                    <div
                      class="nav-link dropdown-toggle"
                      id="userDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span class="mr-2 d-none d-lg-inline text-gray-600 small">
                        {user.name !='' ? user.name:'Admin'}
                      </span>
                      <img
                        class="img-profile rounded-circle"
                        alt=""
                        src="https://source.unsplash.com/QAB-WJcbgJk/60x60"
                      />
                    </div>
        
                    <div
                      class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="userDropdown"
                    >
                      <Link class="dropdown-item" to="/profile">
                        <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                        Profile
                      </Link>
                      <div class="dropdown-divider"></div>
                      <Link
                        class="dropdown-item"
                        onClick={logOut}
                        to="/login"
                      >
                        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>

                        Logout
                      </Link>
                    </div>
                  </li>
                </ul>
              </nav>
            );
        
}


export default Header
