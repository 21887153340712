import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import './Dashboard.css';




function Dashboard() {
  const [dashboard, setDashboard] = useState({});
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    getDashboard();
  }, []);
  const getDashboard = () => {
    axios(`Dashboard/get_store_dashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {

          } else {
            setDashboard(res.data.data);
          }
        }
      })
     .catch((err) => console.log(err));
  };
  
    return (
        <div class="container-fluid">
          <h1>Welcome  !!</h1>
        <div class="row">
          
          <div class="col-xl-6 col-md-6 mb-6">
            <div class="card card-1  h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                <div class="col-auto icon-bg">
                    <i class="fas fa-calendar fa-2x "> </i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Pending Orders
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                    {dashboard && dashboard.pending_orders}
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-6 mb-6">
            <div class="card card-2  h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                <div class="col-auto icon-bg">
                    <i class="fas fa-dollar-sign fa-2x"></i>
                  </div>
                  <div class="col mr-2">

                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Delivered Orders
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                    {dashboard && dashboard.delivered_orders}
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          {/* <div class="col-xl-3 col-md-6 mb-4">
            <div class="card card-3  h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                <div class="col-auto icon-bg">
                    <i class="fas fa-clipboard-list fa-2x "></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Pending Orders
                    </div>
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto">
                        <div class="h5 mb-0 mr-3 font-weight-bold text-white mb-1 ml-2">
                        
                        </div>
                      </div>
                      <div class="col">
                        
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card card-4 border-left-warning  h-100 py-2">
              <div class="card-body">


                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fas fa-comments fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Cancelled Orders
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2"></div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    
    
    )
}

export default Dashboard
