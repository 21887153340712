import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import "./Login.css";
function ResetPassword(props) {
  const [key2, setKey2] = useState(
    props.match && props.match.params && props.match.params.key2
      ? props.match.params.key2
      : ""
  );
  const [key1, setKey1] = useState(
    props.match && props.match.params && props.match.params.key1
      ? props.match.params.key1
      : ""
  );
  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState("");
  useEffect(() => {
      console.log(key1+key2);
    checkResetPassword();
  }, [key1, key2]);

  const checkResetPassword = () => {
    if (key1 && key2) {
      setLoading(true);
      axios(`Users/check_reset_password?key1=${key1}&key2=${key2}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            setUserId(res.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            alert.show(res.data.message);
            history.push({
              pathname: "/login",
              state: { data: true },
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/login",
        state: { data: true },
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password != "" && password === confirmPassword) {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("password", password);
      formdata.append("user_id", userId);
      axios("Users/store_reset_password", {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            setLoading(false);
            alert.show(response.data.message);
            history.push({
              pathname: "/login",
              state: { data: true },
            });
          } else {
            setLoading(false);
            alert.show(response.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert.show("Passowrd Error!");
    }
  };

  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div class="col-lg-6">
                    <div class="p-5">
                      <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-2">Reset Password</h1>
                        <p class="mb-4"></p>
                      </div>
                      <form class="user">
                        <div class="form-group">
                          <input
                            type="password"
                            className="form-control form-control-user"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Password"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            type="password"
                            className="form-control form-control-user"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            id="exampleInputEmail"
                            placeholder="Confirm Password"
                          />
                        </div>
                        <input
                          type="button"
                          class="btn btn-primary btn-user btn-block"
                          onClick={handleSubmit}
                          value="Reset Password"
                        />
                      </form>
                      <hr />
                      <div class="text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default ResetPassword;
