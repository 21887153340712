import React, { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";



import IsAllowed from "./Utils/IsAllowed";
import axios from "./Utils/Axios";
import { AuthContext } from "./Context/AuthContext";



import Header from "./Components/Templates/Header";
import Orders from "./Components/Orders/Orders";
import CodOrders from "./Components/Orders/CodOrders";
import OrderDetails from "./Components/Orders/CodOrderTabs/OrderDetails";

import SideBar from "./Components/Templates/SideBar";
import Footer from "./Components/Templates/Footer";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import Profile from "./Components/Login/Profile";
import ForgotPassword from "./Components/Login/ForgotPassword";
import ResetPassword from "./Components/Login/ResetPassword";
import Feedbacks from "./Components/Orders/FeedBacks";

function App() {


  const { role, setRole, setPermissions } = useContext(AuthContext);

  useEffect(() => {
    if (!role) {
      axios(`Users/get_user_role_permission`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (!res.data.status) {
          } else {
            setRole(res.data.data.role);
            setPermissions(res.data.data.permissions);
          }
        })
        .catch((err) => console.log(err));
    }
  });
  
  return (
    <div id="wrapper">
      <Switch>
        <LoginSideBar />
      </Switch>

      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Switch>
            <LoginHeader />
          </Switch>
          <Switch>
            <ProtectedRoute path="/orders" exact component={Orders} />
            <ProtectedRoute path="/cod-orders" exact component={CodOrders} />
            <ProtectedRoute path="/order-feedback" exact component={Feedbacks} />

            <ProtectedRoute path="/profile" exact component={Profile} />
            <ProtectedRoute path="/order-details/:id" exact component={OrderDetails} />
            
            <Route path="/login" exact component={Login} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route
              path="/reset-password/:key1/:key2"
              exact
              component={ResetPassword}
            />
            <ProtectedRoute path="/" exact component={Dashboard} />
          </Switch>
        </div>
      </div>
      <Switch>
        <LoginFooter />
      </Switch>
    </div>
  );
}

const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};
const LoginHeader = () => {
  return localStorage.getItem("data") ? <Header /> : "";
};
const LoginSideBar = () => {
  return localStorage.getItem("data") ? <SideBar /> : "";
};
const LoginFooter = () => {
  return localStorage.getItem("data") ? <Footer /> : "";
};

export default App;
