import React, { useState,useContext} from "react";
import { useHistory,Link } from "react-router-dom";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from 'react-alert'
import './Login.css';
function Login() {
  const history = useHistory();
  const alert =useAlert();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata= new FormData();
    formdata.append('username',username);
    formdata.append('password',password);
    axios("Login/store_login", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.status === true) {
          localStorage.setItem(
            "data",
            JSON.stringify(response.data.data.api_key)
          );
          
          history.push({
            pathname: "/",
            state: { data: true },
          });

        } else {
          setLoading(false);
          alert.show(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");

  return (
    <div class="container">
       <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "rgba(0, 0, 0, 0.8)",
          },
        }),
      }}
      spinner
      text="Loading ..."
    >
      <div class="row justify-content-center d-flex align-items-center">
        <div class="col-xl-10 col-lg-12 col-md-9 ">
          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                <div class="col-lg-6">
                  <div class="p-5">
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-4">Login</h1>
                    </div>
                    <form class="user" action="/login" method="POST">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control form-control-user"
                          name="username"
                          onChange={(e) => setUserName(e.target.value)}
                          value={username}
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="password"
                          class="form-control form-control-user"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          name="password"
                          id="exampleInputPassword"
                          placeholder="Password"
                        />
                      </div>
                      <input
                        type="button"
                        class="btn btn-primary btn-user btn-block"
                        onClick={handleSubmit}
                        value="Login"
                      />
                      <hr />
                    </form>

                    <div class="text-center">
                        <Link className="small" to="/forgot-password" >
                          Forgot Password?
                        </Link>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </LoadingOverlay>
    </div>
  );
}

export default Login;
