import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

import axios from "../../../Utils/Axios";

import { useHistory } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function DeliveredOrders(props) {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const alert = useAlert();
  useEffect(() => {
    orders_list();
  }, [page, search, per_page, endDate, startDate]);
  
  const orders_list = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/store_order_list?order_status=delivered&page=${page}&per_page=${per_page}&search=${search}&start_date=${start}&end_date=${end}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
          } else {
            //console.log(res.data.data);
            setLoading(false);
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setDeliveredcount(res.data.total_count);
            setPer_page(10);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Deleivered Orders</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
              <div className="col-md-4">
                <DateRangePicker
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Customer</th>
                        <th>Total</th>
                        <th>Order Date</th>
                        <th>Payment Status</th>
                        
                      </tr>
                    </thead>

                    <tbody>
                      {orders.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.order_no}</td>
                            <td>{value.customer}</td>
                            <td>{value.total}</td>
                            <td>{value.date_ordered}</td>
                            <td>
                              {value.payment_status == 0 ? (
                               <span>
                               <h6 style={{ color: "red" }}>UnPaid</h6>
                               
                               </span>
                             ) : (
                               <span>
                               <h6 style={{ color: "green" }}>Paid</h6>
                               {value.payment_method == 1 ? (value.cod_type == 1 ? (<h6> at store <i className="fa fa-credit-card"></i></h6>) : (<h6> at store <i className="fas fa-money-bill-alt"></i></h6>)) : (<h6>On Website</h6>)
                               }

                               </span>
                              )}
                            </td>
                            
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveredOrders;
