import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import axios from "../../../Utils/Axios";
import { useAlert } from "react-alert";
import { DateRangePicker } from "react-dates";
import "./../Orders.css";
import moment from "moment";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function ReadyforShippedOrders(props) {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [userAddress, setUserAddress] = useState({});
  const [addressId, setAddressId] = useState(0);
  const [isItemOpen, setIsItemOpen] = useState(false);
  const [orderItems, setOrderItems] = useState([]);

  const [focusedInput, setFocusedInput] = useState(null);

  const alert = useAlert();
  const customStyles = {
    content: {
      //background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "30%",
      left: "40%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  const customStyles2 = {
    content: {
      background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "30%",
      left: "25%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  function closeItemModal() {
    setIsItemOpen(false);
    setOrderItems([]);
  }

  function openItemModal(items) {
    setIsItemOpen(true);
    setOrderItems(items);
  }

  function closeAddressModal() {
    setIsAddressOpen(false);
    setAddressId(0);
    setUserAddress({});
  }

  function openAddressModal(addressId) {
    setAddressId(addressId);
    setIsAddressOpen(true);
  }

  useEffect(() => {
    orders_list();
  }, [page, per_page, search, endDate, startDate]);

  
  const getUserAddress = () => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("address_id", addressId);
    axios(`Orders/getUserAddress`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          setLoading(false);
          setUserAddress(res.data.data);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateUserAddress = () => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", userAddress.id);
    formdata.append("name", userAddress.name);
    formdata.append("area", userAddress.area);
    formdata.append("city", userAddress.city);
    formdata.append("address", userAddress.address);
    axios(`Orders/updateUserAddress`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          setLoading(false);
          closeAddressModal();
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const orders_list = () => {
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    setLoading(true);
    axios(
      `Orders/store_order_list?order_status=ready_for_ship&page=${page}&per_page=${per_page}&search=${search}&start_date=${start}&end_date=${end}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setOrders([]);
            props.setReadyForShippedcount(res.data.total_count);
            setLoading(false);
          } else {
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setReadyForShippedcount(res.data.total_count);
            //setPer_page(10);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
       <Modal
        isOpen={isItemOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeItemModal}
        style={customStyles2}
        contentLabel="Example Modal"
      >
        <div className="view">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered  ">
                <th>Name</th>
                <th>QTY</th>
                <th>Price</th>
                <th>Special Price</th>
                <tbody>
                  {orderItems &&
                    orderItems.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>{row.name}</td>
                          <td>{row.qty}</td>
                          <td>{row.price}</td>
                          <td>{row.special_price}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isAddressOpen}
        onAfterOpen={getUserAddress}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeAddressModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="view">
          {userAddress && (
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first_name">Name</label>
                      <input
                        class="form-control"
                        placeholder=""
                        type="text"
                        onChange={(e) =>
                          setUserAddress({
                            ...userAddress,
                            name: e.target.value,
                          })
                        }
                        value={userAddress.name}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last_name" class="control-label">
                        Mobile
                      </label>
                      <input
                        class="form-control"
                        placeholder="Mobile No"
                        name="mobile_no"
                        readonly
                        type="text"
                        value={userAddress.mobile_no}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email" class="control-label">
                        Email
                      </label>
                      <input
                        class="form-control"
                        placeholder=""
                        name="email"
                        type="text"
                        readonly
                        value={userAddress.email}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="contact_no" class="control-label">
                        City
                      </label>
                      <input
                        class="form-control"
                        placeholder="City"
                        name="city"
                        type="text"
                        onChange={(e) =>
                          setUserAddress({
                            ...userAddress,
                            city: e.target.value,
                          })
                        }
                        value={userAddress.city}
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="contact_no" class="control-label">
                        Area
                      </label>
                      <input
                        class="form-control"
                        placeholder="Area"
                        name="area"
                        type="text"
                        onChange={(e) =>
                          setUserAddress({
                            ...userAddress,
                            area: e.target.value,
                          })
                        }
                        value={userAddress.area}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="contact_no" class="control-label">
                        Address
                      </label>
                      <input
                        class="form-control"
                        placeholder="Address"
                        name="address"
                        type="text"
                        onChange={(e) =>
                          setUserAddress({
                            ...userAddress,
                            address: e.target.value,
                          })
                        }
                        value={userAddress.address}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => closeAddressModal()}
            >
              Close
            </button>
            <button
              class="btn btn-primary save"
              onClick={() => updateUserAddress()}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Ready for Shipped Orders</h1>

        <div class="popupCard shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
              <div className="col-md-4">
                <DateRangePicker
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Customer</th>
                        <th>Total</th>
                        <th>Order Date</th>
                        <th>Payment Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orders.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.order_no}</td>
                            <td>{value.customer}</td>
                            <td>{value.total}</td>
                            <td>{value.date_ordered}</td>
                            <td>
                              {value.payment_status == 0 ? (
                                <h6 style={{ color: "red" }}>UnPaid</h6>
                              ) : (
                                <h6 style={{ color: "green" }}>Paid</h6>
                              )}
                            </td>

                            <td>
                              <button
                                className="btn btn-sm btn-info"
                                onClick={() =>
                                  openAddressModal(value.shipping_address_id)
                                }
                              >
                                Address
                              </button>
                              <span>
                                <button
                                  className="btn btn-sm btn-warning"
                                  onClick={() =>
                                    openItemModal(value.order_items)
                                  }
                                >
                                  Items
                                </button>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReadyforShippedOrders;
