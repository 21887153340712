import React, { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import {APP_URL} from '../../Constants/constants'
import "./Login.css";
function ForgotPassword() {
  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("email", email);
    formdata.append("url", APP_URL);
    axios("Users/store_forgotpassword", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.status === true) {
          history.push({
            pathname: "/login",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.show(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  
  

  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div class="col-lg-6">
                    <div class="p-5">
                      <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-2">
                          Forgot Your Password?
                        </h1>
                        <p class="mb-4">
                          Just enter your email address below and we'll send you
                          a new Password to your email !
                        </p>
                      </div>
                      <form class="user">
                        <div class="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Email Address..."
                          />
                        </div>
                        <input
                          type="button"
                          class="btn btn-primary btn-user btn-block"
                          onClick={handleSubmit}
                          value="Reset Password"
                        />
                      </form>
                      <hr />
                      <div class="text-center">
                        <Link className="small" to="/login">
                          Already have an account? Login!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default ForgotPassword;
