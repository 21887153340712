import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

//import IsAllowed from "../Utils/IsAllowed";

function SideBar() {
  function toggle() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $(".sidebar .collapse").collapse("hide");
    }
  }

  return (
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion "
      id="accordionSidebar"
    >
      <Link
        class="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div class="sidebar-brand-icon ">
          <img src="/logo.svg" height="150px" width="150px"></img>
        </div>
      </Link>

      <hr class="sidebar-divider my-0" />

      <li class="nav-item active">
        <Link class="nav-link" to="/">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      <hr class="sidebar-divider" />

     

        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <i class="fas fa-fw fa-shopping-cart"></i>
            <span>Orders</span>
          </a>
          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              <Link class="collapse-item" to="/orders">
                Orders
              </Link>

              <Link class="collapse-item" to="/cod-orders">
                COD Orders
              </Link>

              <Link class="collapse-item" to="/order-feedback">
                Feedback
              </Link>
            </div>
          </div>
        </li>
      

      
     

      <div class="text-center d-none d-md-inline">
        <button
          class="rounded-circle border-0"
          onClick={toggle}
          id="sidebarToggle"
        ></button>
      </div>
    </ul>
  );
}

export default SideBar;
