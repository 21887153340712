import React, { useEffect, useState, useRef } from "react";

import LoadingOverlay from "react-loading-overlay";

import axios from "../../Utils/Axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import { DateRangePicker } from "react-dates";
import moment from "moment";


function Feedbacks() {
    const alert = useAlert();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [feedbacks, setFeedbacks] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [per_page, setPer_page] = useState(10);
    const [search, setSearch] = useState("");
    const [focusedInput, setFocusedInput] = useState(null);

    useEffect(() => {
        feedback_list();
    }, [page, search, per_page,startDate,endDate]);

    const feedback_list = () => {
        let start = "";
        startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
        let end = "";
        endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
        setLoading(true);
        axios(
            `Orders/feedback_list?page=${page}&per_page=${per_page}&search=${search}&start_date=${start}&end_date=${end}&delay=1`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-api-key": JSON.parse(localStorage.getItem("data")),
                },
            }
        )
            .then((res) => {
                if (!res.data.status) {
                    setLoading(false);
                } else {
                    setLoading(false);
                    setFeedbacks(res.data.data);
                    setTotal(res.data.total);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <div class="container-fluid">
                <h1 class="h3 mb-2 text-gray-800">Feedbacks</h1>

                <div class="card shadow mb-4">
                    <div class="card-body">
                        {/* <div className="row">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Search...."
                                />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Search...."
                                />
                            </div>
                            <div className="col-md-4">
                                <DateRangePicker
                                    startDate={startDate}
                                    startDateId="s_id"
                                    endDate={endDate}
                                    endDateId="e_id"
                                    showClearDates={true}
                                    isOutsideRange={(day) => moment().diff(day) < 0}
                                    onDatesChange={({ startDate, endDate }) => {
                                        setStartDate(startDate);
                                        setEndDate(endDate);
                                    }}
                                    focusedInput={focusedInput}
                                    onFocusChange={(e) => setFocusedInput(e)}
                                    displayFormat="YYYY-MM-DD"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 30 }}>
                            <div className="table-responsive">
                                <LoadingOverlay
                                    active={loading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: "rgba(255, 255, 255, 0.9)",
                                        }),
                                        spinner: (base) => ({
                                            ...base,
                                            width: "75px",
                                            "& svg circle": {
                                                stroke: "rgba(0, 0, 0, 0.8)",
                                            },
                                        }),
                                    }}
                                    spinner
                                    text="..."
                                >
                                    <table className="table table-bordered  ">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Order Id</th>
                                                <th>Reason </th>
                                                <th>Customer Name</th>
                                                <th>email</th>
                                                <th>Mobile</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {feedbacks.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{value.order_no}</td>
                                                        <td>{value.reason}</td>
                                                        <td>{value.customer_name}</td>
                                                        <td>{value.email}</td>
                                                        <td>{value.mobile_no}</td>
                                                        <td>{value.created_at}</td>

                                                        <td>
                                                            <input
                                                                type="button"
                                                                className="btn btn-primary"
                                                                value="Show"

                                                            ></input>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </LoadingOverlay>

                                <div>
                                    <Box p={2} display="flex" justifyContent="center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                color="secondary"
                                                count={total}
                                                page={page}
                                                onChange={(event, value) => {
                                                    setPage(value);
                                                }}
                                            />
                                        </Stack>
                                        <Stack spacing={2}>
                                            <Select
                                                value={per_page}
                                                onChange={(e) => setPer_page(e.target.value)}
                                            >
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                                <MenuItem value={100}>100</MenuItem>
                                            </Select>
                                        </Stack>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Feedbacks;
