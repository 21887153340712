import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-modal";
import DataTable from "react-data-table-component";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import axios from "../../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { useAlert } from "react-alert";
import { DateRangePicker } from "react-dates";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./../Orders.css";
import moment from "moment";

function PendingOrders(props) {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [cancelReason, setCancelReason] = useState('');


  const [focusedInput, setFocusedInput] = useState(null);
  const alert = useAlert();
  useEffect(() => {
    orders_list();
  }, [page, per_page, search, endDate, startDate]);

  const customStyles = {
    content: {
      //background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "30%",
      left: "40%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  function openCancelModal(order_id) {
    setOrderId(order_id);
    setIsCancelOpen(true);
  }
  function closeCancelModal() {
    setIsCancelOpen(false);
    setOrderId(0);
    setCancelReason('');
  }

  const OrderDetails = (id) => {
    history.push({
      pathname: `/order-details/${id}`,
    });
  };



  const makeOrderCancel = (order_id, reason) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_id", order_id);
    formdata.append("status", 'cancelled');
    formdata.append("reason", reason);
    axios(`Orders/updateStoreOrderStatus`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          closeCancelModal();
          orders_list();
          setLoading(false);
        } else {
          closeCancelModal();
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };


  const orders_list = () => {
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    setLoading(true);
    axios(
      `Orders/store_cod_order_list?order_status=pending&page=${page}&per_page=${per_page}&search=${search}&start_date=${start}&end_date=${end}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setOrders([]);
            props.setPendingcount(res.data.total_count);
            setLoading(false);
          } else {
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setPendingcount(res.data.total_count);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };



  return (
    <div>
      <Modal
        isOpen={isCancelOpen}
        // onAfterOpen={afterOpenModal}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeCancelModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="view">
          <div className="card">
            <h4>Cancel Reason</h4>
            <div className="row" style={{ justifyContent: "center" }}>
              <input className="form-control" onChange={(e) => setCancelReason(e.target.value)}></input>
            </div>

            <div className="btn-row" style={{ justifyContent: "center" }}>
              <button
                className="btn btn-sm btn-danger btn-modal"
                type="button"
                onClick={closeCancelModal}
              >
                Close
              </button>
              <button
                className="btn btn-sm btn-success btn-modal"
                onClick={() => makeOrderCancel(orderId, cancelReason)}
              >
                Cancel Order
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Pending Orders</h1>

        <div className="popupCard shadow mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
              <div className="col-md-4">
                <DateRangePicker
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Customer</th>
                        <th>Total</th>
                        <th>Order Date</th>
                        <th>Payment Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orders.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <strong>{value.order_no}</strong> <br></br>
                              <span style={{ color: "red" }}>
                                {value.order_status}
                              </span>
                            </td>
                            <td>{value.customer}</td>
                            <td><span>{"Total : " + value.total}</span><br></br>
                              {value.pending_amount > 0 && (
                                <span style={{ color: "red" }}>{"Pending : " + value.pending_amount}</span>)}</td>
                            <td>{value.date_ordered}</td>
                            <td>
                              {value.payment_status == 0 ? (
                                <h6 style={{ color: "red" }}>UnPaid</h6>
                              ) : (
                                <h6 style={{ color: "green" }}>Paid</h6>
                              )}
                            </td>

                            <td>
                              <span>
                                <button
                                  className="btn btn-sm btn-info"
                                  onClick={() =>
                                    OrderDetails(value.id)
                                  }
                                >
                                  Detail
                                </button>
                              </span>
                              {value.installment == 1 ? (
                                <span>
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() =>
                                      openCancelModal(
                                        value.id,
                                      )
                                    }
                                  >
                                    Cancel
                                  </button>
                                </span>
                              ) : ''}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>
                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingOrders;
