import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Orders.css";
import PendingOrders from "./OrderTabs/PendingOrders";
import DeliveredOrders from "./OrderTabs/DeliveredOrders";
import ReadyforShippedOrders from "./OrderTabs/ReadyforShippedOrders";
import ShippedOrders from "./OrderTabs/ShippedOrders";
import BankTransferOrders from "./OrderTabs/BankTransferOrders";

function Products() {
  const [deliveredcount, setDeliveredcount] = useState(0);
  const [pendingcount, setPendingcount] = useState(0);
  const [readyForShippedcount, setReadyForShippedcount] = useState(0);
  const [shippedcount, setShippedcount] = useState(0);
  const [bankTransfercount, setBankTransfercount] = useState(0);

  return (
    <Tabs>
      <TabList>
        <Tab>
          Pending{" "}
          <span class="badge badge-danger badge-counter">{pendingcount}</span>
        </Tab>
        <Tab>
          Bank Transfer {" "}
          <span class="badge badge-primary badge-counter">{bankTransfercount}</span>
        </Tab>
        <Tab>
          Ready For Shipped{" "}
          <span class="badge badge-warning badge-counter">{readyForShippedcount}</span>
        </Tab>
        <Tab>
        Shipped{" "}
          <span class="badge badge-info badge-counter">{shippedcount}</span>
        </Tab>
        <Tab>
          Delivered{" "}
          <span class="badge badge-success badge-counter">
            {deliveredcount}
          </span>
        </Tab>
      </TabList>

      <TabPanel>
        <PendingOrders setPendingcount={setPendingcount}></PendingOrders>
      </TabPanel>
      <TabPanel>
        <BankTransferOrders setBankTransfercount={setBankTransfercount}></BankTransferOrders>
      </TabPanel>
      <TabPanel>
        <ReadyforShippedOrders setReadyForShippedcount={setReadyForShippedcount}></ReadyforShippedOrders>
      </TabPanel>
      <TabPanel>
        <ShippedOrders setShippedcount={setShippedcount}></ShippedOrders>
      </TabPanel>
      <TabPanel>
        <DeliveredOrders
          setDeliveredcount={setDeliveredcount}
        ></DeliveredOrders>
      </TabPanel>
    </Tabs>
  );
}

export default Products;
